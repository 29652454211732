import React from 'react';
import './MobileNav.scss';
import Backdrop from '../UI/Backdrop/Backdrop';
import { HashLink } from 'react-router-hash-link';

const MobileNav = props => {
  return (
    <React.Fragment>
      {props.visible && (
        <Backdrop onClick={props.onClick}>
          <div id="mobile-nav">
            <ul>
              <li>
                <HashLink to="/#projects">Projects</HashLink>
              </li>
              <li>
                <HashLink to="/#certifications">Certificates</HashLink>
              </li>
              <li>
                <HashLink to="/#about">About</HashLink>
              </li>
              <li>
                <HashLink to="/#contact">Contact</HashLink>
              </li>
              <li>
                <HashLink to="/legal#legalDetails">Legal Information</HashLink>
              </li>
              <li>
                <HashLink to="/legal#dsgvo-english">Privacy Policy</HashLink>
              </li>
              <li>
                <HashLink to="/legal#impressum">Impressum</HashLink>
              </li>
              <li>
                <HashLink to="/legal#dsgvo-german">Datenschutz</HashLink>
              </li>
            </ul>
          </div>
        </Backdrop>
      )}
    </React.Fragment>
  );
};

export default MobileNav;
