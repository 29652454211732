import React, { useReducer } from 'react';
import './CoverFlow.scss';

import { LazyImage } from '../../utility/LazyImage';

const requestImageFileSmall = require.context(
  '../../../assets/images/certification_small',
  false,
  /.$/
);

const requestImageFile = require.context(
  '../../../assets/images/certification',
  false,
  /.$/
);

const slides = [
  {
    imageSmall: 'frontend_libraries_small.jpg',
    image: 'frontend_libraries.png',
  },
  {
    imageSmall: 'github_small.jpg',
    image: 'github.png',
  },
  {
    imageSmall: 'html_css_small.jpg',
    image: 'html_css.png',
  },
  {
    imageSmall: 'javascript_fcc_small.jpg',
    image: 'javascript_fcc.png',
  },
  {
    imageSmall: 'javascript_small.jpg',
    image: 'javascript.png',
  },
  {
    imageSmall: 'nextjs_small.jpg',
    image: 'nextjs.png',
  },
  {
    imageSmall: 'wbs_small.jpg',
    image: 'wbs.png',
  },
  {
    imageSmall: 'react_native_small.jpg',
    image: 'react_native.png',
  },
  {
    imageSmall: 'react_small.jpg',
    image: 'react.png',
  },
  {
    imageSmall: 'responsive_web_design_small.jpg',
    image: 'responsive_web_design.png',
  },
  {
    imageSmall: 'webentwickler_small.jpg',
    image: 'webentwickler.png',
  },
];

const initialState = {
  slideIndex: Math.round(slides.length / 2),
};

const slidesReducer = (state, action) => {
  if (action.type === 'NEXT') {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % slides.length,
    };
  }
  if (action.type === 'PREV') {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1,
    };
  }
  if (action.type === 'GOTO') {
    return {
      ...state,
      slideIndex: action.value,
    };
  }
};

const getPictureURLSmall = slide => {
  let pictureURLSmall = '';
  try {
    pictureURLSmall = requestImageFileSmall(`./${slide.imageSmall}`).default;
  } catch (err) {
    console.log(`Picture not found: `, err.message);
  }
  return pictureURLSmall;
};

const getPictureURL = slide => {
  let pictureURL = '';
  try {
    pictureURL = requestImageFile(`./${slide.image}`).default;
  } catch (err) {
    console.log(`Picture not found: `, err.message);
  }
  return pictureURL;
};

function Slide({ slide, offset, onClick }) {
  const active = offset === 0 ? true : null;

  return (
    <div
      className="slide"
      data-active={active}
      onClick={onClick}
      style={{
        '--offset': offset,
        '--dir': offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      {/* <img src={getPictureURLSmall(slide)} alt={`preview ${getPictureURL(slide)}`} /> */}
      <LazyImage src={getPictureURLSmall(slide)} alt={`preview ${getPictureURL(slide)}`} />
    </div>
  );
}

const CoverFlow = props => {
  const [state, dispatch] = useReducer(slidesReducer, initialState);

  const onClickHandler = (index, slide) => {
    if (index === state.slideIndex) {
      window.open(getPictureURL(slide));
    } else {
      dispatch({ type: 'GOTO', value: index });
    }
  };

  return (
    <div className="slides">
      {slides.map((slide, index) => {
        let offset = state.slideIndex - index;
        return (
          <Slide
            key={index}
            slide={slide}
            offset={offset}
            onClick={onClickHandler.bind(this, index, slide)}
          />
        );
      })}
    </div>
  );
};

export default CoverFlow;
