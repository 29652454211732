import React from 'react';
import './Welcome.scss';
import { HashLink } from 'react-router-hash-link';

import ProfilePicture from '../../assets/welcome.png';
import BackgroundImage from '../../assets/bg_code.jpg';
import { LazyImage } from '../utility/LazyImage';

const Welcome = props => {
  const onBackgroundLoadHandler = () => {
    const backgroundContainer = document.getElementById(
      'welcome__background'
    );
    backgroundContainer.style.backgroundImage = `url(${BackgroundImage})`;
  };

  return (
    <div id="welcome">
      <div id="welcome__container">
        <div id="welcome__background">
          <img
            style={{display: 'none'}}
            src={BackgroundImage}
            alt=""
            onLoad={onBackgroundLoadHandler}
          ></img>
        </div>
        <div id="welcome__image-wrapper">
          <LazyImage src={ProfilePicture} alt="profile" />
        </div>
        <div id="welcome__text-area-wrapper">
          <article id="welcome__text-area">
            <p>
              <span className="text-big">Hi, i'm Stephan,</span>
            </p>
            <p className="text-with-tab">
              a freelancing fullstack web developer.
            </p>
            <p></p>
            <p className="flex">
              <span>Got any interesting project?</span>
              <HashLink id="welcome__action" to="/#contact">
                Contact Me
              </HashLink>
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
