setTimeout(() => {
  const submitBtn = document.getElementById('submit');

  const nameInput = document.getElementById('name');

  const emailInput = document.getElementById('email');

  const nameLabel = document.getElementById('name-label');
  const emailLabel = document.getElementById('email-label');

  let nameNotValid, emailNotValid;

  if (!nameInput) return;
  
  nameInput.addEventListener('input', event => {
    validFn('name', true, nameInput, nameLabel, 'Name', 'Please enter a name!');
  });

  emailInput.addEventListener('input', event => {
    validFn(
      'email',
      true,
      emailInput,
      emailLabel,
      'Email',
      'Please enter a valid email address!'
    );
  });

  const manageSubmitButton = () => {
    if (nameNotValid || emailNotValid) {
      submitBtn.disabled = true;
      submitBtn.textContent = 'Form Data is not valid!';
    } else {
      submitBtn.disabled = false;
      submitBtn.textContent = 'Submit';
    }
  };

  const validFn = (
    type,
    required,
    inputEl,
    inputLabel,
    labelTextValid,
    labelTextNotValid
  ) => {
    const notValidFn = () => {
      inputLabel.textContent = labelTextNotValid;
      inputLabel.classList.add('label-not-valid');
      inputEl.classList.add('not-valid');
    };

    const validFn = () => {
      inputLabel.textContent = labelTextValid;
      inputLabel.classList.remove('label-not-valid');
      inputEl.classList.remove('not-valid');
    };

    const emailRegEx = /^.+@.+\..+$/;

    switch (type) {
      case 'name':
        if (inputEl.value.trim() === '' && required) {
          nameNotValid = true;
          notValidFn();
        } else {
          nameNotValid = false;
          validFn();
        }
        break;
      case 'email':
        if (!emailRegEx.test(inputEl.value.trim()) && required) {
          emailNotValid = true;
          notValidFn();
        } else {
          emailNotValid = false;
          validFn();
        }
        break;
      default:
    }

    manageSubmitButton();
  };


  let submitButtonTimeOut;

  submitBtn.addEventListener('mouseover', event => {

    submitButtonTimeOut = setTimeout(() => {
      validFn('name', true, nameInput, nameLabel, 'Name', 'Please enter a name!');
      validFn(
        'email',
        true,
        emailInput,
        emailLabel,
        'Email',
        'Please enter a valid email address!'
      );
  
      manageSubmitButton();
    }, 500);
  });

  submitBtn.addEventListener('mouseout', event => {
    clearTimeout(submitButtonTimeOut);
  })

}, 0);
