import React, { useContext } from 'react';
import './Projects.scss';

import Project from './Project/Project';
import { ProjectsContext } from '../../context/projects-context';

const Projects = props => {
  const { projects } = useContext(ProjectsContext);

  const projectsList = projects.map(project => {
    return <Project key={project.id} id={project.id} />
  })

  return (
    <div id="projects">
      <div id="projects__container">
        <div id="projects__header">
          <h2>Projects</h2>
        </div>
        <div id="projects__list-wrapper">
          {projectsList}
        </div>
      </div>
    </div>
  );
};

export default Projects;
