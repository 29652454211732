import React from 'react';
import './About.scss';

const About = props => {
  return (
    <div id="about">
      <div id="about__container">
        <div id="about__header">
          <h2>About</h2>
        </div>
        <article id="about__text-area">
          <p>
            Working as a developer has been a dream of mine for a long time.
            <br />
            That's the reason, why i changed my career in 2019.
          </p>
          <br />
          <p>
            Before becoming a fullstack-developer, i worked as a control
            technician in the automation industry.
            <br />
            There i developed solutions in process automation, measurement and
            control technology.
          </p>
          <br />
          <p>
            Formerly i set up complete processes in plastics industry with
            KUKA-robots at national and international locations.
          </p>
          <br />
          <p>
            Over the last years i got in touch with different technologies of
            web-development.
            <br />
            Unfortunately there was no possibility for improving my
            programming-skills in my former career.
            <br />
            That's one of the reason's why i quitted my job 2019 and started to
            learn web- and app-development in a deeper way with autodidaktive
            learning by online-courses.
            <br />
            In summer 2020 i joined a 15-week-course from WBS-Coding-School,
            where i learned the skills of a junior fullstack-developer. After
            this course, i went on with learning and practicing and i started my
            first projects.
          </p>
          <br />
          <p>
            Highly motivated, i am now looking forward to new opportunities and
            challenges.
          </p>
          <br />
          <p>
            Feel free to{' '}
            <mark>
              <a href="#contact">contact me</a>
            </mark>
            . I am happy to provide you with more information.
          </p>
        </article>
      </div>
    </div>
  );
};

export default About;
