import React from 'react';
import './Backdrop.scss';

const Backdrop = props => {
    return (
        <div id="backdrop" onClick={props.onClick}>
            {props.children}
        </div>
    )
}

export default Backdrop;