import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './Website.css';

import Header from './components/Header/Header';
import MobileNav from './components/MobileNav/MobileNav';
import Welcome from './components/Welcome/Welcome';
import Projects from './components/Projects/Projects';
import Certifications from './components/Certifications/Certifications';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import LegalDetails from './pages/LegalDetails/LegalDetails';
import Impressum from './pages/Impressum/Impressum';
import DSGVOGerman from './pages/DSGVOGerman/DSGVOGerman';
import DSGVOEnglish from './pages/DSGVOEnglish/DSGVOEnglish';

function Website() {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);

  const onMobileNavClickHandler = () => {
    setMobileNavVisible(visible => !visible);
  };

  return (
    <Router>
      <div className="Website">
        <Header onHamburgerClick={onMobileNavClickHandler} />
        <MobileNav
          visible={mobileNavVisible}
          onClick={onMobileNavClickHandler}
        />
        <Switch>
          <Route path="/legal">
            <LegalDetails />
            <DSGVOEnglish />
            <Impressum />
            <DSGVOGerman />
          </Route>
          <Route path="/">
            <Welcome />
            <Projects />
            <Certifications />
            <About />
            <Contact />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default Website;
