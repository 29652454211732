import React, { useState, useEffect } from 'react'

const styles = {
    image: {
        display: 'block',
        height: '100%',
        width: '100%',
    }
}

const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='

  export const LazyImage = ({ src, alt }) => {
    const [imageSrc, setImageSrc] = useState(placeHolder)
    const [imageRef, setImageRef] = useState()
  
    useEffect(() => {
      let observer
      let didCancel = false
  
      if (imageRef && imageSrc === placeHolder) {
        if (IntersectionObserver) {
          observer = new IntersectionObserver(
            entries => {
              entries.forEach(entry => {
                // when image is visible in the viewport + rootMargin
                if (
                  !didCancel &&
                  (entry.intersectionRatio > 0 || entry.isIntersecting)
                ) {
                  setImageSrc(src)
                }
              })
            },
            {
              threshold: 0.01,
              rootMargin: '25%',
            }
          )
          observer.observe(imageRef)
        } else {
          // Old browsers fallback
          setImageSrc(src)
        }
      }
      return () => {
        didCancel = true
        // on component unmount, we remove the listner
        if (observer && observer.unobserve) {
          observer.unobserve(imageRef)
        }
      }
    }, [imageRef, imageSrc, src])
  
    return <img styles={styles.image} ref={setImageRef} src={imageSrc} alt={alt} />
  }