import React from 'react';
import './Header.scss';
import { HashLink } from 'react-router-hash-link';
import BackgroundImage from '../../assets/bg_code.jpg';

const Header = props => {
  const onBackgroundLoadHandler = () => {
    const backgroundContainer = document.getElementById(
      'header__background'
    );
    backgroundContainer.style.backgroundImage = `url(${BackgroundImage})`;
  };
  return (
    <React.Fragment>
      <div id="header">
        <div id="header__background">
        <img
            style={{display: 'none'}}
            src={BackgroundImage}
            alt=""
            onLoad={onBackgroundLoadHandler}
          ></img>
        </div>
        <div id="header__container">
          <HashLink to="/#"><div id="header__logo">HIVOMEDIA</div></HashLink>
          <nav id="header__nav">
            <ul>
                <li><HashLink to="/#projects">Projects</HashLink></li>
                <li><HashLink to="/#certifications">Certificates</HashLink></li>
                <li><HashLink to="/#about">About</HashLink></li>
                <li><HashLink to="/#contact">Contact</HashLink></li>
                <li><HashLink to="/legal#legalDetails">Legal</HashLink></li>
            </ul>
          </nav>
          <nav id="header__mobile-nav" onClick={props.onHamburgerClick}>
              <div id="header__hamburger">
                <div></div>
                <div></div>
                <div></div>
              </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
