import React from 'react';
import './Footer.scss';
import { HashLink } from 'react-router-hash-link';
import BackgroundImage from '../../assets/bg_code.jpg';

const Footer = props => {
  const onBackgroundLoadHandler = () => {
    const backgroundContainer = document.getElementById(
      'footer__background'
    );
    backgroundContainer.style.backgroundImage = `url(${BackgroundImage})`;
  };
  return (
    <React.Fragment>
      <div id="footer">
        <div id="footer__background">
        <img
            style={{display: 'none'}}
            src={BackgroundImage}
            alt=""
            onLoad={onBackgroundLoadHandler}
          ></img>
        </div>
        <div id="footer__container">
          <div id="footer__copyright">&copy; hivomedia 2021</div>
          <nav id="footer__nav">
            <ul>
                <li><HashLink to="/legal#legalDetails">Legal Information</HashLink></li>
                <li><HashLink to="/legal#dsgvo-english">Privacy Policy</HashLink></li>
                <li><HashLink to="/legal#impressum">Impressum</HashLink></li>
                <li><HashLink to="/legal#dsgvo-german">Datenschutz</HashLink></li>
            </ul>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
