import React from 'react';
import './Certifications.scss';

import CoverFlow from '../UI/CoverFlow/CoverFlow';

const Certifications = props => {
  return (
    <div id="certifications">
      <div id="certifications__container">
        <div id="certifications__header">
          <h2>Certificates</h2>
        </div>
        <div id="certifications__list-wrapper">
          <CoverFlow />
          you can click on us ...
        </div>
      </div>
    </div>
  );
};

export default Certifications;
