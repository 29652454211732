import React, { useContext } from 'react';
import './Project.scss';

import BackgroundImage from '../../../assets/bg_code.jpg';
import { ProjectsContext } from '../../../context/projects-context';
import { LazyImage } from '../../utility/LazyImage';

const requestImageFile = require.context(
  '../../../assets/images/projects_small',
  false,
  /.$/
);

const Project = props => {
  const { projects } = useContext(ProjectsContext);

  const [project] = projects.filter(project => project.id === props.id);

  let pictureURL = '';
  try {
    pictureURL = requestImageFile(`./${project.smallPicture}`).default;
  } catch (err) {
    console.log(`small Picture not found: `, err.message);
  }

  const onBackgroundLoadHandler = () => {
    const backgroundContainerList = document.getElementsByClassName(
      'project__header'
    );
    Array.from(backgroundContainerList).forEach(container => {
      container.style.backgroundImage = `url(${BackgroundImage})`;
    });
  };

  return (
    <div className="project">
      <div className="project__header">
        <h3>{project.title}</h3>
        <img
          style={{ display: 'none' }}
          src={BackgroundImage}
          alt=""
          onLoad={onBackgroundLoadHandler}
        ></img>
      </div>
      <div className="project__container">
        <div className="project__picture">
          <a
            href={project.liveUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LazyImage src={pictureURL} alt={`preview ${project.title}`} />
          </a>
        </div>
        <div className="project__description">
          <p>{project.shortDescription}</p>
        </div>
        <a
          className="project__button"
          href={project.liveUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Watch Live
        </a>
      </div>
    </div>
  );
};

export default Project;
