import React, { useState } from 'react';
import './Contact.scss';
import validationScript from './validation.js';
import axios from 'axios';

const API_PATH = 'https://www.hivomedia.de/apitest/contact/index.php';

const Contact = props => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
    mailSent: false,
    error: null,
  });

  const onSubmitHandler = event => {
    event.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: formState,
    })
      .then(result => {
        setFormState({
          ...formState,
          name: '',
          email: '',
          message: '',
          mailSent: result.data.sent,
        });
      })
      .catch(error => {
        setFormState({ ...formState, error: error.message });
        console.log(error.message);
      });
  };

  const onChangeInputHandler = (key, event) => {
    setFormState({
      ...formState,
      mailSent: false,
      [key]: event.target.value,
    });
  };

  return (
    <div id="contact">
      <div id="contact__container">
        <div id="contact__header">
          <h2>Contact</h2>
        </div>
        <form
          action="#"
          id="contact__form"
          autoComplete="off"
          noValidate
          onSubmit={onSubmitHandler}
        >
          <div className="input-container">
            <label className="label-main" id="name-label" htmlFor="name">
              Name
            </label>
            <input
              className="input-field"
              type="text"
              id="name"
              required
              placeholder="your name"
              value={formState.name}
              onChange={onChangeInputHandler.bind(this, 'name')}
            />
          </div>

          <div className="input-container">
            <label className="label-main" id="email-label" htmlFor="email">
              Email
            </label>
            <input
              className="input-field"
              type="email"
              id="email"
              required
              placeholder="your email address"
              value={formState.email}
              onChange={onChangeInputHandler.bind(this, 'email')}
            />
          </div>

          <div className="input-container">
            <label className="label-main" htmlFor="message">
              your message:
            </label>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              value={formState.message}
              onChange={onChangeInputHandler.bind(this, 'message')}
            ></textarea>
          </div>

          <button type="submit" id="submit">
            Submit
          </button>
          <div>
            {formState.mailSent && <div>Thank you for contacting me.</div>}
          </div>
        </form>
        <script src={validationScript} />
      </div>
    </div>
  );
};

export default Contact;
