import React from 'react';

export const ProjectsContext = React.createContext();

const contextData = {
  projects: [
    {
      id: 1,
      title: 'profile-card',
      shortDescription:
        'My first project on frontendmentor.io, a great website for improving frontend skills.',
      smallPicture: 'profile_card_small.png',
      liveUrl: 'https://www.hivomedia.de/challenges/profile-card'
    },
    {
        id: 2,
        title: 'drum-machine',
        shortDescription:
          'A challenge from freecodecamp, i really loved to work on, because of playing sounds.',
        smallPicture: 'drum_machine_small.png',
        liveUrl: 'https://www.hivomedia.de/challenges/drum-machine'
      },
      {
        id: 3,
        title: 'calculator',
        shortDescription:
          'My result of the freecodecamp calculator-challenge',
        smallPicture: 'calculator_small.png',
        liveUrl: 'https://www.hivomedia.de/challenges/calculator'
      },
      {
        id: 4,
        title: 'quote-machine ',
        shortDescription:
          'A site, where different quotes where shown, also a challenge from freecodecamp i solved',
        smallPicture: 'quote_machine_small.png',
        liveUrl: 'https://www.hivomedia.de/challenges/quote-machine'
      },
      {
        id: 5,
        title: 'landing-page',
        shortDescription:
          'A simple responsive product landing page challenge from freecodecamp',
        smallPicture: 'landing_page_small.png',
        liveUrl: 'https://www.hivomedia.de/challenges/landing-page'
      },
      {
        id: 6,
        title: 'markdown-viewer',
        shortDescription:
          'A markdown editor with live preview function. a challenge from freecodecamp',
        smallPicture: 'markdown_previewer_small.png',
        liveUrl: 'https://www.hivomedia.de/challenges/markdown-previewer'
      },
      {
        id: 7,
        title: 'pomodoro-timer',
        shortDescription:
          'My result of the 25+5 timer from freecodecamp created with React.',
        smallPicture: 'pomodoro_timer_small.png',
        liveUrl: 'https://www.hivomedia.de/challenges/pomodoro-timer'
      },
      {
        id: 8,
        title: 'survey-form',
        shortDescription:
          'The survey form challenge from freecodecamp where i implemented validation as extra.',
        smallPicture: 'survey_form_small.png',
        liveUrl: 'https://www.hivomedia.de/challenges/survey-form'
      },
      {
        id: 9,
        title: 'tribute-page',
        shortDescription:
          'My first challenge from freecodecamp. I tributes this page to a person, who has to do with the universe.',
        smallPicture: 'tribute_page_small.png',
        liveUrl: 'https://www.hivomedia.de/challenges/tribute-page'
      },
  ],
};

const ProjectsContextProvider = props => {
  return (
    <ProjectsContext.Provider value={contextData}>
      {props.children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContextProvider;
