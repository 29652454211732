import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Website from './Website';

import ProjectsContextProvider from './context/projects-context';

ReactDOM.render(
  <React.StrictMode>
    <ProjectsContextProvider>
      <Website />
    </ProjectsContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
